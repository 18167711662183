import { IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype } from 'src/graphql/generated/hooks'
import { FilterOption, TaxonomyType } from 'src/types'

export const basicPlanFeatures = [
  'Compare products',
  'Create a list of followed solutions',
  'Leave ratings and reviews',
]

export const premiumPlanFeatures = [
  // 'Compare products based on 100+ different fields',
  // 'View premium insights',
  // 'View buyers’ guides',
  // 'View testimonials',
  // 'View screenshots and videos',
  'Access to Solution Guides',
  'Access to Premium Knowhow',
  'Access to Vendor Analyses',
  'Access to Legaltech Resources for Lawyers',
]

export const vendorPlanFeatures = [
  'Create listings',
  'Claim and update existing listings',
  'Upgrade your listings',
  'Communicate with buyers',
]

export const vendorResourceFilters: FilterOption = {
  title: TaxonomyType.ResourceType,
  data: [
    { name: 'Article', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.Article },
    { name: 'Case Study', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.CaseStudy },
    { name: 'Checklist', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.Checklist },
    {
      name: 'Practical Guidance',
      id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.PracticalGuidance,
    },
    { name: 'Press Release', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.PressRelease },
    { name: 'White Paper', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.WhitePaper },
    { name: 'Product Briefing', id: IEnum_Componentsolutionpropsvendorresourceexternal_Documenttype.ProductBriefing },
  ],
}

export const fundingInfosC = [
  { value: 'Other', label: 'Other' },
  { value: 'Pre_Seed', label: 'Pre Seed' },
  { value: 'Seed', label: 'Seed' },
  { value: 'Series_A', label: 'Series A' },
  { value: 'Series_B', label: 'Series B' },
  { value: 'Series_C', label: 'Series C' },
  { value: 'Series_D', label: 'Series D' },
  { value: 'NA', label: 'NA' },
]
export const fundingInfo = ['Other', 'Pre_Seed', 'Seed', 'Series_A', 'Series_B', 'Series_C', 'Series_D', 'NA']

export const fundingAmountsC = [
  { value: 'Less_than_One_M', label: '< $1M' },
  { value: 'One_To_Five_M', label: '$1 - 5M' },
  { value: 'Five_To_Ten_M', label: '$5 - 10M' },
  { value: 'Ten_To_TwentyFive_M', label: '$10 - 25M' },
  { value: 'TwentyFive_To_Fifty_M', label: '$25 - 50M' },
  { value: 'Fifty_To_Hundred_M', label: '$50 - 100M' },
  { value: 'More_Than_Hundred_M', label: '> $100M' },
  { value: 'NA', label: 'NA' },
]
