import cn from 'classnames'
import Image from 'next/image'
import { CompatibleLink } from 'src/components/CompatibleLink/CompatibleLink'
import { IExpertCardDataFragment } from 'src/graphql/generated/hooks'
import { useDefaultLogo } from 'src/hooks/useDefaultLogo'
import { removeTags } from 'src/utils/removeTags'

interface Props {
  expert: IExpertCardDataFragment
  className?: string
}

export const ExpertCard: React.FC<Props> = ({ className, expert }) => {
  const avatar = useDefaultLogo(expert.attributes?.avatar?.data?.attributes?.url)
  const title = `${removeTags(expert.attributes?.title || '')}` || ''
  const displayName = `${removeTags(expert.attributes?.displayName || '')}` || ''
  return (
    <CompatibleLink
      href={`/experts/${expert.attributes?.slug}/`}
      passHref
      className="cursor-pointer flex flex-col items-center justify-center"
    >
      <div
        className={cn(
          'w-[120px] h-[120px] flex items-center justify-center overflow-hidden rounded-[60px] object-cover',
          className,
        )}
      >
        <Image src={avatar} alt={displayName} priority width={120} height={120} />
      </div>
      {displayName && (
        <p className="font-secondary font-bold text-center text-textdark text-[1rem] mt-3">{displayName}</p>
      )}
      {title && <p className="font-secondary text-darkgray text-center text-[0.8rem]">{title}</p>}
    </CompatibleLink>
  )
}
