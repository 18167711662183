import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import IonIcon from '@reacticons/ionicons'
import cn from 'classnames'
import React, { useMemo } from 'react'
import Slider from 'react-slick'
import { useExpertsQuery } from 'src/graphql/generated/hooks'

import { ExpertCard } from '../ExpertCard/ExpertCard'

export const ExpertsCarousel: React.FC = () => {
  const { data } = useExpertsQuery()
  const experts = useMemo(() => data?.experts?.data || [], [data])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: experts.length > 5 ? 5 : experts.length,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: (
      <div className="cursor-pointer">
        <IonIcon name="chevron-forward-outline" className="!h-[30px] !w-[30px] text-[black] ml-auto"></IonIcon>
      </div>
    ),
    prevArrow: (
      <div className="cursor-pointer">
        <IonIcon name="chevron-back-outline" className="!h-[30px] !w-[30px] text-[black] ml-auto"></IonIcon>
      </div>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    <div className={cn('w-full px-5 sm:px-0 flex flex-row justify-center bg-white')}>
      <div className="max-w-screen-xl mx-32 w-full">
        <p className="font-bold w-full text-center mt-10 text-[1.5rem]">Meet the Experts</p>
        <p className="w-full text-center text-[#323232]">
          Our solution guides are written by our panel of experts. All leaders in their fields sharing their expertise
        </p>
        <div className="m-10 h-[200px]">
          <Slider {...settings} adaptiveHeight={false}>
            {experts.map((expert, idx) => (
              <ExpertCard key={idx} expert={expert} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}
